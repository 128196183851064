export const AbsenceMotive = [
  { value: null, text: "Selecione o motivo", disabled: true },
  { value: "Licença Maternidade", text: "Licença Maternidade" },
  { value: "Licença Saúde", text: "Licença Saúde" },
  {
    value: "Licença para ocupar cargo público",
    text: "Licença para ocupar cargo público",
  },
  {
    value: "Licença para fazer curso de aperfeiçoamento no exterior",
    text: "Licença para fazer curso de aperfeiçoamento no exterior",
  },
  { value: "Motivo Excepcional", text: "Motivo Excepcional" },
];

export const NoteAbsenceMotive = {
  "Licença Maternidade":
    "Observação: Enviar a certidão do nascimento do filho ou declaração da maternidade",
  "Licença Saúde":
    "Observação: Enviar o atestado contendo o período de afastamento",
  "Licença para ocupar cargo público":
    "Observação: Enviar o documento que comprove que ele passou em uma posição de órgão público desde que assinado por um responsável, por exemplo, edital",
  "Licença para fazer curso de aperfeiçoamento no exterior":
    "Observação: Enviar o comprovante de matrícula do curso e Documento que mostre o prazo de conclusão/duração do curso",
  "Motivo Excepcional": "",
  "": "",
};
