<template>
  <div>
    <p class="p4">
      <i class="fas fa-info-circle"></i>
      Importante: Você deve inserir o(s) documento(s) necessário(s) conforme sua
      solicitação.
    </p>
    <b-card class="custom-card my-4" border-variant="light">
      <b-form-row>
        <b-col>
          Motivo Afastamento
        </b-col>
        <b-col>
          <b-form-select
            v-model.lazy.trim="form.absenceMotive"
            :state="validateField('absenceMotive')"
            :options="absenceMotiveOptions"
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          Data de Início
        </b-col>
        <b-col>
          <date-picker
            datePickerId="startDate"
            :key="form.startDate"
            :dataValue="form.startDate"
            :state="validateFieldStartDate('startDate')"
            @datePickerChangedValue="onChangedStartDate"
            :required="true"
          />
        </b-col>
        <div
          class="invalid text-invalid-right"
          v-show="validateFieldStartDate('startDate') == false"
        >
          {{ startDateErrorMsg }}
        </div>
      </b-form-row>
      <b-form-row>
        <b-col>
          Data de Término
        </b-col>
        <b-col>
          <date-picker
            datePickerId="endDate"
            :key="form.endDate"
            :dataValue="form.endDate"
            :state="validateFieldEndDate('endDate', 'startDate')"
            @datePickerChangedValue="onChangedEndDate"
            :required="true"
          />
        </b-col>

        <div
          class="invalid text-invalid-right"
          v-show="validateFieldEndDate('endDate', 'startDate') == false"
        >
          {{ endDateErrorMsg }}
        </div>
      </b-form-row>

      <b-form-row v-show="showAntecipateTemporaryAid()">
        <b-col>
          Auxílio Temporário
        </b-col>
        <b-col class="d-flex">
          <b-form-checkbox
            class="ml-auto"
            switch
            v-model="form.temporaryAid"
            :state="validateCheckBox('temporaryAid')"
          >
            {{ form.temporaryAid ? "Sim" : "Não" }}
          </b-form-checkbox>
        </b-col>
        <p class="mt-4" v-show="form.temporaryAid">
          Observação: Os pagamentos dos benefícios de auxílios temporários são
          efetuados sempre na conta pessoa física do cooperado. Podem incidir
          impostos sobre os pagamentos.
        </p>
      </b-form-row>
      <b-form-row>
        <b-col class="d-flex flex-column align-items-start">
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="true"
            :description="'Anexar documento para comprovação'"
            :msgErro="'É necessário inserir um anexo com o comprovante.'"
            :state="validateFieldFile('files')"
            :required="true"
            v-model.lazy.trim="form.files"
          />
          <p class="p3">{{ noteAbsenceMotive[form.absenceMotive] }}</p>
        </b-col>
      </b-form-row>
    </b-card>
  </div>
</template>

<script>
import DatePicker from "../../../../components/date-picker/DatePicker.vue";
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { inject } from "@vue/composition-api";
import {
  AbsenceMotive,
  NoteAbsenceMotive,
} from "../../../../static-data/AbsenceMotive.js";
import {
  validateField,
  requiredFieldsFilled,
  validateFieldFile,
  validateCheckBox,
  validateFieldStartDate,
  validateFieldEndDate,
} from "../../../../utils/validate/validate.js";

const DefaultForm = {
  name: null,
  absenceMotive: null,
  startDate: null,
  endDate: null,
  temporaryAid: false,
  files: [],
  endDateErrorMsg: "",
  startDateErrorMsg: "",
};

export default {
  components: {
    UploadFile,
    DatePicker,
  },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      startDateKey: null,
      endDateKey: null,
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      endDateErrorMsg: "",
      startDateErrorMsg: "",
      absenceMotiveOptions: AbsenceMotive,
      noteAbsenceMotive: NoteAbsenceMotive,
      validateField,
      requiredFieldsFilled,
      validateFieldFile,
      validateCheckBox,
      validateFieldStartDate,
      validateFieldEndDate,
    };
  },
  methods: {
    initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
      this.startDateKey = this.form.startDate;
      this.endDateKey = this.form.endDate;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    onChangeFormSelect(formData) {
      if (!formData) {
        this.form = { ...DefaultForm };
        this.setForm(this.form);
        this.formData = {
          form: { ...this.form },
          onSubmitHandler: this.onSubmit,
        };

        return;
      }

      const temporaryAid =
        formData.temporaryAid && formData.temporaryAid.toLowerCase() == "sim";

      const form = {
        ...this.form,
        ...formData,
        temporaryAid: temporaryAid,
      };

      this.form = form;
      this.setForm(this.form);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
    showAntecipateTemporaryAid() {
      if (
        this.form.absenceMotive == "Licença Maternidade" ||
        this.form.absenceMotive == "Licença Saúde"
      )
        return true;
      return false;
    },
    onChangedStartDate(value) {
      this.form.startDate = value;
    },
    onChangedEndDate(value) {
      this.form.endDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-invalid-right {
  width: 100%;
  text-align: right;
}
</style>
