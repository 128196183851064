import { returnDateFormat } from "../../utils/date/date-utils";

export function mapperToAbsenceRequest(formData) {
  return {
    "Ação da solicitação": formData.name,
    "Motivo Afastamento": formData.absenceMotive,
    "Data de Início": returnDateFormat(formData.startDate),
    "Data de Término": returnDateFormat(formData.endDate),
    "Auxílio Temporario": formData.temporaryAid ? "Sim" : "Não",
  };
}

export function mapperToAbsenceAntecipationRequest(formData) {
  return {
    "Ação da solicitação": formData.name,
    "Intenção do Retorno": formData.anticipationReturn,
  };
}
