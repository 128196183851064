import axios from "../http-client/axios";
import { METALICA_URL } from "../urls/base-urls";
import AlterType from "../../static-data/TypeRequestedFlow";
import requestBuilder from "../request-builder/request-builder";
import { mapperToAbsenceRequest, mapperToAbsenceAntecipationRequest } from "./absence-mapper";
import FormRequestAction from "../../static-data/FormRequestAction";
import ServiceRequestType from "../../static-data/ServiceRequestType";


export async function addAbsence(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAbsenceRequest(formData, FormRequestAction.insert),
      AlterType.save,
      attachments,
      ServiceRequestType.DOCTOR_ABSENCE_SEND
    )
  );
  return resp.data;
}

export async function addAbsenceDelay(formData, attachments) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAbsenceRequest(formData, FormRequestAction.insert),
      AlterType.save,
      attachments,
      ServiceRequestType.DOCTOR_ABSENCE_DELAY_SEND
    )
  );
  return resp.data;
}

export async function addAAbsenceAnticipation(formData) {
  const resp = await axios.post(
    `${METALICA_URL}/v2/integration-flow`,
    requestBuilder(
      mapperToAbsenceAntecipationRequest(formData, FormRequestAction.insert),
      AlterType.save,
      [],
      ServiceRequestType.DOCTOR_ABSENCE_ANTECIPATION_SEND
    )
  );
  return resp.data;
}