<template>
  <div>
    <AlterFormLayout
      modalId="add-delay-absence-modal"
      formTitle="Solicitar adiamento"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação de adiamento"
      :onSubmitEvent="onSubmit"
      :disableEvent="this.$store.getters.isAdmin"
    >
      <template #content>
        <AbsenceForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Olá Cooperado(a), acusamos o recebimento de sua solicitação e as tratativas estão em andamento."
      buttonText="Ok, entendi!"
      subdescription="Em breve retornaremos."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import AbsenceForm from "./AbsenceForm.vue";
import AlterFormLayout from "../../../../layouts/AlterFormLayout.vue";
import SuccessModal from "../../../../components/base-modals/SuccessModal.vue";

import { addAbsenceDelay } from "../../../../services/absence/absence-service";
export default {
  components: {
    AlterFormLayout,
    AbsenceForm,
    SuccessModal,
  },
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    async onSubmit(formData) {
      formData.name = "Solicitação de Adiamento";
      this.protocol = await addAbsenceDelay(formData, formData.files);
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
  },
};
</script>
